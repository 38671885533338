/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios'
import { notify as notiwind } from 'notiwind'

window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const byId = (id) => document.getElementById(id)

const notify = (
  text,
  title='Notification',
  type='info',
  timeout = 2000
) => {
  notifyInfo(title, type, text, timeout)
}

const notifyInfo = (
  text, title='Notification', type='info', timeout = 2000
) => {
  notiwind({
    group: 'generic', title, type, text
  }, timeout)
}

const notifySuccess = (
  text, title='Notification', type='success', timeout = 2000
) => {
  notiwind({
    group: 'generic', title, type, text
  }, timeout)
}

const notifyWarning = (
  text, title='Notification', type='warning', timeout = 2000
) => {
  notiwind({
    group: 'generic', title, type, text
  }, timeout)
}

const notifyError = (
  text, title='Notification', type='error', timeout = 2000
) => {
  notiwind({
    group: 'generic', title, type, text
  }, timeout)
}

window.byId = byId

window.notify = notify
window.notifyInfo = notifyInfo
window.notifySuccess = notifySuccess
window.notifyWarning = notifyWarning
window.notifyError = notifyError

// import Echo from 'laravel-echo'

// import Pusher from 'pusher-js'
// window.Pusher = Pusher

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: import.meta.env.VITE_PUSHER_APP_KEY,
//   cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//   wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//   wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//   wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//   forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//   enabledTransports: ['ws', 'wss'],
// })
