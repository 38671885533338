import './bootstrap'
import '../css/app.css'

import 'floating-vue/dist/style.css'
import 'primevue/resources/themes/tailwind-light/theme.css'

import '../css/floating-vue.css'

import { createApp, h } from 'vue'
import { createInertiaApp, usePage } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import FloatingVue from 'floating-vue'
import Notifications from 'notiwind'
import PrimeVue from 'primevue/config'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import queryString from 'query-string'

import axios from 'axios'

import * as Sentry from '@sentry/vue'
import * as SentryIntegrations from '@sentry/integrations'

const env = import.meta.env
const appName = env.VITE_APP_NAME || 'Laravel'

window.queryString = queryString

axios.interceptors.response.use(undefined, function (error) {
    if (error?.status === 401) {
      window.alert('Your login has expired\nYou need to re-login.')
    }

    return Promise.reject(error)
})

createInertiaApp({
  title: (title) => `${appName} - ${title}`,

  resolve: (name) => resolvePageComponent(
    `./Pages/${name}.vue`,
    import.meta.glob('./Pages/**/*.vue')
  ),

  setup({el, App, props, plugin}) {
    const hasSentry = env?.VITE_SENTRY_DSN?.length||0 > 0
    const app = createApp({
      render: () => h(App, props)
    })

    if (hasSentry) {
      Sentry.init({
        app,
        dsn: env.VITE_SENTRY_DSN,
        integrations: [
          // Sentry.browserTracingIntegration({router}),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
          Sentry.replayCanvasIntegration(),
          SentryIntegrations.captureConsoleIntegration({
            // Add 'warn', 'error', 'debug', 'assert' console output types
            // to Sentry
            // and additionally possible to add 'log' and 'info'
            // console output types to Sentry if required
            levels: ['log', 'info', 'warn', 'error', 'debug', 'assert'],
          }),
          SentryIntegrations.contextLinesIntegration({
              frameContextLines: 500,
          }),
          SentryIntegrations.httpClientIntegration(),
          SentryIntegrations.debugIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: env.VITE_SENTRY_TRACES_SAMPLE_RATE,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: ['*'],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0,
      })
    }

    window.recaptchaEnabled = (env?.VITE_RECAPTCHA_KEY?.length||0)
      > 0

    app.config.globalProperties.$copyText = window.copyText

    // noinspection JSCheckFunctionSignatures
    app.use(plugin)
      .use(FloatingVue)
      .use(Notifications)
      .use(PrimeVue)
      .use(ZiggyVue)
      // .use(VueReCaptcha, {
      //   siteKey: env.VITE_RECAPTCHA_KEY
      // })

    if (hasSentry) {
      app.mixin(Sentry.createTracingMixins({ trackComponents: true }))

      Sentry.attachErrorHandler(app, { logErrors: true })
    }

    const page = usePage()

    if (page?.props?.auth?.user) {
      Sentry.setUser({
        id: page.props.auth.user.id,
        email: page.props.auth.user.email,
      })
    }

    return app.mount(el)
  },

  progress: { color: '#4B5563' },
})
